import request from "@/utils/http";
//首页检测个人信息
export function UsersCheck(params = {}) {
  return request({
    url: '/Users/check',
    method: 'get',
    params
  })
}
//获取验证码
export function userSendSms(params = {}) {
    return request({
        url: '/Users/sendSms',
        method: 'post',
        data: params
    })
}
//个人账号开通登录
export function userLogin(params = {}) {
    return request({
        url: '/Users/logon',
        method: 'post',
        data: params
    })
}
//个人账号退出登录
export function userlogout(params = {}) {
  return request({
      url: '/Users/logout',
      method: 'post',
      data: params
  })
}
// 奖品信息
export function prizeInfo(data) {
  return request({
    url: '/Users/prizeInfo',
    method: 'post',
    data
  })
}
// 个人积分-抽奖
export function prizeDraw(data) {
  return request({
    url: '/Users/prizeDraw',
    method: 'post',
    data
  })
}
// 抽奖规则
export function UserArticle(params = {}) {
  return request({
    url: '/Index/article',
    method: 'get',
    params
  })
}