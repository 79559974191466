<template>
  <div class="all_cont">
    <div class="top_content">
      <div class="top_first">
        <img
        v-if="headimg"
          :src="headimg"
          alt=""
        />
        <img
        v-else
          src="../assets/images/my/header.png"
          alt=""
        />
        <div>
          <p>{{ identity == 1?name:'游客' }}</p>
          <span v-if="phone">{{ phone }}</span>
        </div>
      </div>
      <div class="top_second" v-if="identity == 1">
        <div class="ts_left">
          <p>我的积分</p>
          <div class="tsl_foot">
            <span>{{ points||0 }}</span>
            <span>分</span>
            <div class="tslf_detail" @click="jumDetail">明细</div>
          </div>
        </div>
        <img @click="jumpRouter('/Points/luckyDraw')" src="../assets/images/my/btn_jfcj.png" alt="" />
      </div>
      <div class="top_third" v-if="identity == 1"></div>
    </div>
    <div :class="{ 'center_cont': true, 'center_yont': identity == 0 }">
      <div class="cc_content" @click="jumpRouter('/orderHome/index')">
        <div class="cne_left">
          <img src="../assets/images/my/icon_cx_.png" alt="" />
          <span>订单查询</span>
        </div>
        <img src="../assets/images/my/icon_yjt_.png" alt="" />
      </div>
      <div class="cc_content" @click="jumpRouter('/replaceIMEI/apply/0')">
        <div class="cne_left">
          <img src="../assets/images/my/icon_imei.png" alt="" />
          <span>IMEI更换</span>
        </div>
        <img src="../assets/images/my/icon_yjt_.png" alt="" />
      </div>
      <div class="cc_content" @click="jumpRouter('/PhotoResubmission')">
        <div class="cne_left">
          <img src="../assets/images/my/icon_tpbj.png" alt="" />
          <span>照片补交</span>
        </div>
        <img src="../assets/images/my/icon_yjt_.png" alt="" />
      </div>
      <div class="cc_content" style="border: none" v-if="identity == 1" @click="jumpRouter('/Points/leaderboard')">
        <div class="cne_left">
          <img src="../assets/images/my/icon_phb_.png" alt="" />
          <span>排行榜</span>
        </div>
        <img src="../assets/images/my/icon_yjt_.png" alt="" />
      </div>
      <div class="cc_content" style="border: none" v-if="false">
        <div class="cne_left">
          <img src="../assets/images/my/icon_lxwm.png" alt="" />
          <span>联系我们</span>
        </div>
        <img src="../assets/images/my/icon_yjt_.png" alt="" />
      </div>
    </div>
    <div class="footer_bottom" v-if="identity == 1" @click="goOut">退出登录</div>
    <div class="footer_bottom" v-else @click="gooutLogin">登录</div>
  </div>
</template>

<script>
import { Toast } from 'vant'
import {UsersCheck,userlogout} from "../api/myORlogin"
export default {
  name: "",
  mixins: [],
  components: {},
  props: {},
  data() {
    return {
      identity: 0,
      name:'',
      headimg:'',
      points:"",
      phone:''
    };
  },
  computed: {},
  watch: {},
  created() {
    this.getusersCheck()
  },
  mounted() {},
  methods: {
    goOut(){
      Toast.clear()
      userlogout().then(res=>{
        Toast('操作成功！');
        window.localStorage.removeItem("token")
        this.getusersCheck()
      })
    },
    jumDetail(){
      this.$router.push('/Points/points')
    },
    jumpRouter(url){
        this.$router.push(url)
    },
    gooutLogin(){
      this.$router.push('/login')
    },
    getusersCheck(){
       UsersCheck().then(res=>{
           console.log(res);
           this.identity=res.data.identity
           this.name=res.data.name
           this.headimg=res.data.headimg
           this.phone=res.data.phone
           this.points=res.data.points
        }).catch(err=>{
          this.identity=err.data.identity
           this.name=err.data.name
           this.headimg=err.data.headimg
           this.phone=err.data.phone
           this.points=err.data.points
        })
    }
  },
};
</script>

<style scoped lang="less">
.footer_bottom {
  position: fixed;
  left: 25px;
  bottom: 150px;
  line-height: 100px;
  text-align: center;
  font-family: PingFang SC;
  font-weight: bold;
  font-size: 28px;
  color: #666666;
  width: 700px;
  height: 100px;
  background: #ffffff;
  border-radius: 50px;
}
.cne_left {
  display: flex;
  align-items: center;
  font-family: PingFang SC;
  font-weight: bold;
  font-size: 28px;
  color: #333333;
  > img {
    margin-right: 20px;
    width: 35px;
    height: 34px;
  }
}
.cc_content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 640px;
  height: 110px;
  background: #ffffff;
  border-radius: 20px 20px 0px 0px;
  border-bottom: 1px solid #eeeeee;
  > img {
    width: 12px;
    height: 22px;
  }
}
.center_cont {
  margin: -10px auto 0 auto;
  padding: 0 30px;
  width: 700px;
  background: #ffffff;
  border-radius: 20px;
}
.center_yont {
  margin: -170px auto 0 auto;
}
.all_cont {
  background: #f2f3f4;
  width: 100vw;
  height: 100vh;
  .top_content {
    padding: 1px 0;
    background-image: url(../assets/images/my/mybg.png);
    width: 100vw;
    height: 400px;
    background-size: 100% 100%;
  }
  .top_third {
    margin-top: -14px;
    padding: 1px 0;
    background-image: url(../assets/images/my/img_.png);
    width: 100vw;
    height: 100px;
    background-size: 100% 100%;
  }
  .top_second {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: auto;
    padding: 1px 25px;
    background-image: url(../assets//images/my/bg_img.png);
    width: 670px;
    height: 146px;
    background-size: 100% 100%;
    img {
      width: 190px;
      height: 60px;
    }
    .ts_left {
      font-family: PingFang SC;
      font-weight: 500;
      font-size: 24px;
      color: #ffffff;
    }
    .tsl_foot {
      display: flex;
      align-items: baseline;
      span {
        font-family: DIN;
        font-weight: bold;
        font-size: 50px;
        color: #ffffff;
      }
      span:nth-of-type(2) {
        font-weight: 500;
        font-size: 24px;
      }
      .tslf_detail {
        margin-left: 5px;
        padding: 5px 5px 3px 5px;
        line-height: 21px;
        font-weight: 400;
        font-size: 22px;
        color: #ffffff;
        border-radius: 6px;
        border: 1px solid #ffffff;
      }
    }
  }
  .top_first {
    margin: 48px 40px 40px 40px;
    height: 108px;
    display: flex;
    align-items: center;
    img {
      margin-right: 20px;
      width: 108px;
      height: 108px;
      border-radius: 50%;
    }
    p {
      font-family: Source Han Sans CN;
      font-weight: bold;
      font-size: 36px;
      color: #333333;
    }
    span {
      font-family: PingFang SC;
      font-weight: 500;
      font-size: 28px;
      color: #333333;
    }
  }
}
</style>
